import { render, staticRenderFns } from "./Medicine.vue?vue&type=template&id=729b2fdc&scoped=true"
import script from "./Medicine.vue?vue&type=script&lang=ts"
export * from "./Medicine.vue?vue&type=script&lang=ts"
import style0 from "./Medicine.vue?vue&type=style&index=0&id=729b2fdc&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "729b2fdc",
  null
  
)

export default component.exports